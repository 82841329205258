import { Box } from '@mui/joy'
import { cloneElement, Children, useState } from 'react'
import { TfiPlus } from 'react-icons/tfi'
import { Flex } from '../Flex'
import { Control } from './Control'
import { Panel } from './Panel'

export function Accordion({ children }) {
    const [isOpen, setIsOpen] = useState(false)

    const ControlComponent = Children.toArray(children).find(
        (child) => child.type === Accordion.Control
    )

    const PanelComponent = Children.toArray(children).find(
        (child) => child.type === Accordion.Panel
    )

    const toggle = () => setIsOpen(!isOpen)

    return (
        <Box
            border="1px solid #fff"
            padding={4}
            onClick={toggle}
            bgcolor="#fff"
            sx={{
                cursor: 'pointer',
            }}
        >
            <Flex alignItems="center" justifyContent="space-between">
                <div>{cloneElement(ControlComponent)}</div>
                <Flex
                    w={24}
                    h={24}
                    alignItems="center"
                    sx={{
                        transition: 'all .3s ease',
                        transform: isOpen ? 'rotate(90deg)' : null,
                    }}
                >
                    <TfiPlus color="#0d417d" size={24} />
                </Flex>
            </Flex>
            {isOpen && <Box pt={4}>{cloneElement(PanelComponent)}</Box>}
        </Box>
    )
}

Accordion.Panel = Panel
Accordion.Control = Control
