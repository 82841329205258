import { Text } from '../Text'

export default function Heading({
    children,
    color = '#0d417d',
    weight = 700,
    sx = {},
    ...rest
}) {
    return (
        <Text
            component="h2"
            fontWeight={weight}
            fontSize={{
                md: '56px',
                sm: '42px',
                xs: '36px',
            }}
            sx={{
                ...sx,
                color,
                letterSpacing: '-0.5px',
                lineHeight: 1.1,
            }}
            // textColor={color}
            {...rest}
        >
            {children}
        </Text>
    )
}
