import dynamic from 'next/dynamic'
import { Main } from '../components'

const Footer = dynamic(
    () => import('../components/Footer').then((mod) => mod.Footer),
    { ssr: true }
)
const Header = dynamic(
    () => import('../components/Header').then((mod) => mod.Header),
    { ssr: true }
)

export default function DefaultLayout({
    children,
    siteTitle,
    siteDescription,
    menuItems,
    footerMenuItems,
    className,
    noFooter,
}) {
    return (
        <>
            <Header
                title={siteTitle}
                description={siteDescription}
                menuItems={menuItems}
            />
            <Main className={className}>{children}</Main>
            {!noFooter && <Footer menuItems={footerMenuItems} />}
        </>
    )
}
