import { Box, Grid } from '@mui/joy'
import Image from 'next/image'
import AspectRatio from '@mui/joy/AspectRatio'
import Link from 'next/link'
import { useRouter } from 'next/router'
import flatListToHierarchical from '../../utilities/flatListToHierarchical'
import { Divider } from '../Divider'
import { Flex } from '../Flex'
import NavigationItem from '../Header/NavigationItem'
import { Section } from '../Section'
import { Text } from '../Text'

const footerTitle =
    'EZ Capital is a financial technology company, not a bank. Business financing is provided by EZ Capital and/or its partners.'

const renderMenuItems = (items, span = 4) =>
    items.map((item) => {
        const { id, path, label, children } = item

        // eslint-disable-next-line no-underscore-dangle
        if (item.__typename !== 'MenuItem') {
            return null
        }

        return (
            <Grid xs={12} sm={span} key={id}>
                <NavigationItem
                    key={id}
                    label={label}
                    path={path}
                    fontSize="1.1rem"
                />
                {children.map((child) => (
                    <NavigationItem
                        key={child.id}
                        label={child.label}
                        path={child.path}
                        fontWeight={300}
                        sx={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                        }}
                    />
                ))}
            </Grid>
        )
    })

export default function Footer({ menuItems }) {
    const hierarchicalMenuItems = flatListToHierarchical(menuItems)
    const router = useRouter()
    // console.log(hierarchicalMenuItems.length)
    const total = hierarchicalMenuItems.length
    return (
        <footer>
            <Section bg="#fff">
                <Grid container spacing="15px">
                    {router.locale === 'zh' && (
                        <Grid md={3} sm={12} xs={12}>
                            <Image
                                quality={80}
                                src="/images/Footer/EZ_Capital_QrCode.jpg"
                                alt="QR Code"
                                width={200}
                                height={200}
                            />
                        </Grid>
                    )}
                    <Grid md={9} sm={12} xs={12} container>
                        {renderMenuItems(hierarchicalMenuItems, 12 / total)}
                    </Grid>
                </Grid>

                {/* 加入社交媒体链接，微信和linkedin */}
                <Flex alignItems="flex-end">
                    <Box flex={1} />

                    <Box width="48px">
                        <Link
                            href="https://work.weixin.qq.com/kfid/kfc9271eeb5bff25b3c"
                            target="_blank"
                        >
                            <AspectRatio ratio="1 / 1">
                                <Image
                                    // quality={80}
                                    src="/images/Footer/wechat-logo.png"
                                    fill
                                    alt="EZ Capital Logo"
                                    style={{
                                        objectFit: 'cover',
                                    }}
                                />
                            </AspectRatio>
                        </Link>
                    </Box>
                    <Box width="48px">
                        <Link
                            href="https://www.linkedin.com/company/ez-capital-group-inc"
                            target="_blank"
                        >
                            <AspectRatio ratio="1 / 1">
                                <Image
                                    // quality={80}
                                    src="/images/Footer/linkedin-logo.png"
                                    fill
                                    alt="EZ Capital Logo"
                                    style={{
                                        objectFit: 'cover',
                                    }}
                                />
                            </AspectRatio>
                        </Link>
                    </Box>
                </Flex>

                <Divider my={10} />
                <Flex justifyContent="space-between" pb={10} flexWrap="wrap">
                    <Text
                        fontWeight={300}
                        fontSize={{
                            xs: '0.9rem',
                            sm: '1rem',
                        }}
                        width={{
                            xs: '100%',
                            sm: 'auto',
                        }}
                        pb={{
                            xs: 10,
                            sm: 0,
                        }}
                    >
                        © EZ Capital 2023, All rights reserved.
                    </Text>
                    <Flex
                        justifyContent={{
                            sm: 'flex-end',
                        }}
                        flex={1}
                    >
                        <Text
                            component="a"
                            pr={8}
                            fontWeight={300}
                            fontSize={{
                                xs: '0.9rem',
                                sm: '1rem',
                            }}
                            borderRight="1px solid #353c41"
                            href="/EZ Capital Fulfillment Policy.pdf"
                        >
                            Fulfillment Policy
                        </Text>
                        <Text
                            component="a"
                            pl={8}
                            pr={8}
                            fontWeight={300}
                            fontSize={{
                                xs: '0.9rem',
                                sm: '1rem',
                            }}
                            borderRight="1px solid #353c41"
                            href="/privacy_policy.pdf"
                        >
                            Privacy Policy
                        </Text>
                        <Text
                            component="a"
                            pl={8}
                            fontSize={{
                                xs: '0.9rem',
                                sm: '1rem',
                            }}
                            fontWeight={300}
                            href="/EZ Capital Business Financing Terms & Conditions of Service.pdf" //
                        >
                            Terms and Conditions of Service
                        </Text>
                    </Flex>
                </Flex>
                <Text fontWeight={300}>{footerTitle}</Text>
                <Text mb={4} fontWeight={300}>
                    All applications are subject to approval. Additional fees
                    may apply.
                </Text>
                <Text mb={4} fontWeight={300}>
                    Certain financing products and/or services may not be
                    available in certain states.
                </Text>
            </Section>
        </footer>
    )
}
