import { styled } from '@mui/joy/styles'

export const Overlay = styled('div')({
    '&::before': {
        content: "''",
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `linear-gradient(102deg, #08080a 3%, rgba(12, 20, 37, 0))`,
    },
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
})
