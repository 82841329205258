import { Box } from '@mui/joy'
import { styled } from '@mui/joy/styles'

const SIZE_STYLE = {
    xs: {
        padding: '0.4rem 0.8rem',
    },
    sm: {
        padding: '0.8rem 1rem',
    },
    md: {
        padding: '.8rem 3rem',
    },
    lg: {
        padding: '16px 50px',
    },
}

const STYLES = {
    backgroundColor: '#ffd44d',
    border: '1px solid transparent',
    color: '#0d417d',
    outline: 0,
    borderRadius: '32px',
    fontWeight: 700,
    // fontFamily: 'Lato',
    fontSize: '17px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',

    '&:hover': {
        // backgroundColor: '#ffd44d90',
    },
    '&:active': {
        transform: 'scale(0.95)',
    },
    '&:disabled': {
        backgroundColor: '#C0C0C0',
        border: '1px solid transparent',
        color: 'grey',
        cursor: 'not-allowed',
    },
}

function UnstyledButton({ children, size = 'md', ...rest }) {
    return (
        <Box
            component="button"
            {...rest}
            sx={STYLES}
            p={SIZE_STYLE[size]?.padding}
        >
            {children}
        </Box>
    )
}

const OutlinedButton = styled(UnstyledButton)(() => ({
    backgroundColor: 'transparent',
    border: '1px solid #ffcc00',
    color: '#ffcc00',
    '&:hover': {
        color: '#000',

        backgroundColor: '#ffcc00',
    },
}))

const GhostButton = styled(UnstyledButton)(() => ({
    backgroundColor: '#fff',
    border: '1px solid transparent',
    color: '#1c2436',
    '&:hover': {
        backgroundColor: '#ffcc00',
    },
}))

// const DisabledButton = styled(UnstyledButton)(() => ({
//     backgroundColor: '#C0C0C0',
//     border: '1px solid transparent',
//     color: 'grey',
//     cursor: 'not-allowed',
//     '&:active': {
//         transform: 'scale(1)',
//     },
// }))

const ButtonVariants = {
    default: UnstyledButton,
    outlined: OutlinedButton,
    ghost: GhostButton,
    // clickDsisabled: DisabledButton,
}

export function Button({ children, variant, ...rest }) {
    const Component = ButtonVariants[variant] || UnstyledButton

    return <Component {...rest}>{children}</Component>
}
