import { Box, Stack, Grid } from '@mui/joy'
import Image from 'next/image'
import AspectRatio from '@mui/joy/AspectRatio'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { Button } from '../../Button'
import { Content } from '../../Content'
import { Popover } from '../../Popover'
import { Heading, Paragraph, Text } from '../../Text'

export default function HeroCTA({
    title,
    description,
    btnTitle,
    heroImage,
    vertical = true,
    jumpTo = '',
    helperText,
}) {
    const { t } = useTranslation('common')
    if (vertical)
        return (
            <Content>
                <Heading
                    size="68px"
                    color="#fff"
                    mb={0}
                    sx={{
                        lineHeight: '1.1',
                    }}
                >
                    {title}
                </Heading>
                {description && (
                    <Paragraph
                        size="1.25rem"
                        mb="30px"
                        sx={{
                            color: '#fff',
                        }}
                    >
                        {description}
                    </Paragraph>
                )}

                {btnTitle && (
                    <Button size="lg" color="yellow">
                        {btnTitle}
                    </Button>
                )}
            </Content>
        )

    return (
        <Grid
            pt={{
                xs: '16px',
                sm: '70px',
                md: '70px',
            }}
            container
            rowSpacing={15}
            columnSpacing={{
                xs: 0,
                sm: 5,
                md: 10,
                lg: 15,
            }}
        >
            <Grid xs={12} md={6}>
                <Stack
                    spacing={{
                        xs: 4,
                        sm: 8,
                    }}
                    sx={{
                        height: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <Heading
                        mb={0}
                        fontSize={{
                            xs: '2.5rem',
                            sm: '3rem',
                            md: '3.5rem',
                            lg: '4rem',
                        }}
                        color="#0d417d"
                        sx={{
                            whiteSpace: 'break-spaces',
                        }}
                    >
                        {title}
                    </Heading>
                    <Paragraph
                        fontSize={{
                            xs: '1.25rem',
                            sm: '1.5rem',
                        }}
                        color="#0d417d"
                        sx={{
                            whiteSpace: 'break-spaces',
                        }}
                        fontWeight={300}
                    >
                        {description}
                    </Paragraph>
                    <Box>
                        <Link href={jumpTo} target="_blank">
                            <Button
                                color="yellow"
                                // variant="yellow"

                                size="lg"
                            >
                                {btnTitle}
                            </Button>
                        </Link>
                    </Box>
                    <Paragraph
                        color="#0d417d"
                        fontWeight={300}
                        fontSize=".9rem"
                    >
                        {helperText}
                        <Popover
                            component="span"
                            placement="top-start"
                            content={
                                <Text fontSize=".9rem" maxWidth={360} p={1}>
                                    {t('scoreHelperText')}
                                </Text>
                            }
                            pl={1}
                        />
                    </Paragraph>
                </Stack>
            </Grid>
            <Grid xs={12} md={6}>
                <AspectRatio ratio="1 / 1">
                    <Image
                        quality={80}
                        src={heroImage}
                        fill
                        style={{
                            objectFit: 'cover',
                            borderRadius: '45% 0% 0 0%',
                        }}
                        alt="Hero Image"
                    />
                </AspectRatio>
            </Grid>
        </Grid>
    )
}
