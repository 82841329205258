import { Box } from '@mui/joy'
import Link from 'next/link'
import { Button } from '../Button'
import { Card } from '../Card'
import { Center } from '../Center'
import { Text } from '../Text'
import { Content } from '../Content'

export default function CTA2({
    title,
    description,
    primaryBtnTitle,
    primaryBtnLink,
    helperText,
}) {
    return (
        <Card p={4}>
            <Content maxW={480} gap={16}>
                <Text
                    textAlign="center"
                    fontWeight={700}
                    fontSize="1.75rem"
                    pt={5}
                    color="#0d417d"
                >
                    {title ?? 'Ready to grow your business?'}
                </Text>
                <Text textAlign="center" pb={4}>
                    {description ?? 'Start your free application now.'}
                </Text>
                <Center pb={4}>
                    <Link href={primaryBtnLink || '#'} target="_blank">
                        <Button>{primaryBtnTitle}</Button>
                    </Link>
                </Center>
                <Box mb={4}>
                    <Text
                        textAlign="center"
                        fontSize=".9rem"
                        sx={{
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {`${helperText}`}
                    </Text>
                </Box>
            </Content>
        </Card>
    )
}
