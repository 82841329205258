import { Grid } from '@mui/joy'
import { Children } from 'react'

const DEFAULT_COLS = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3,
}

export function SimpleGrid({ children, cols = DEFAULT_COLS, ...rest }) {
    return (
        <Grid {...rest}>
            {Children.toArray(children).map((child, index) => (
                <Grid
                    xs={cols.xs}
                    sm={cols.sm}
                    md={cols.md}
                    lg={cols.lg}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`Grid-Item-${index}`}
                >
                    {child}
                </Grid>
            ))}
        </Grid>
    )
}
