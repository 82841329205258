import Box from '@mui/joy/Box'
import { useState } from 'react'
import PopperUnstyled from '@mui/base/PopperUnstyled'
import { MdInfoOutline } from 'react-icons/md'

export function Popover({ children, placement = 'top', content, ...rest }) {
    const [isOpen, setIsOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleMouseEnter = (event) => {
        // console.log(event.currentTarget)
        setAnchorEl(event.currentTarget)
        setIsOpen(true)
    }

    const handleMouseLeave = () => {
        setAnchorEl(null)
        setIsOpen(false)
    }
    return (
        <>
            <Box
                {...rest}
                position="relative"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
                <Box
                    position="absolute"
                    left="100%"
                    top={0}
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    <MdInfoOutline />
                </Box>
            </Box>
            <PopperUnstyled
                anchorEl={anchorEl}
                open={isOpen}
                placement={placement}
                // zIndex={100}
                slotProps={{
                    root: {
                        style: {
                            zIndex: 100,
                        },
                    },
                }}
                keepMounted
            >
                <Box backgroundColor="#fff" p={1}>
                    {content}
                </Box>
            </PopperUnstyled>
        </>
    )
}
