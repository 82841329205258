/* eslint-disable react/no-danger */
import Head from 'next/head'
import className from 'classnames/bind'
import styles from './Article.module.scss'

const cx = className.bind(styles)

export default function Article({ content }) {
    return (
        <>
            <Head>
                <link rel="stylesheet" href="/wordpress.css" />
            </Head>
            <article className={cx('component')}>
                <div dangerouslySetInnerHTML={{ __html: content ?? '' }} />
            </article>
        </>
    )
}
