import { Stack, Box, Grid, Button } from '@mui/joy'
import { ImPhone } from 'react-icons/im'
import Image from 'next/image'
import AspectRatio from '@mui/joy/AspectRatio'
import Link from 'next/link'
import { Flex } from '../Flex'
import { Heading, Text } from '../Text'

export default function CTA({
    title,
    description,
    primaryBtnTitle,
    primaryBtnLink,
    phoneNumber,
    phoneHelperText,
    // helperText,
    image,
}) {
    return (
        <Grid container>
            <Grid xs={12} sm={6}>
                <Stack spacing={5}>
                    <Heading color="#00b9e4" mb={0}>
                        {title ?? 'Ready to grow your business?'}
                        {/* {title} */}
                    </Heading>
                    <Text color="#00b9e4">
                        {description ?? 'Start your free application now.'}
                    </Text>
                    <Stack
                        direction={{
                            xs: 'column',
                            md: 'row',
                        }}
                        spacing={3}
                        alignItems={{
                            xs: 'flex-start',
                            md: 'center',
                        }}
                        flexWrap="wrap"
                        pb={{
                            xs: 4,
                            md: 0,
                        }}
                    >
                        <Box>
                            <Link href={primaryBtnLink || '#'} target="_blank">
                                <Button color="primary" size="lg">
                                    {primaryBtnTitle}
                                </Button>
                            </Link>
                        </Box>
                        <Link href={`tel:${phoneNumber}`}>
                            <Flex alignItems="center">
                                <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                    backgroundColor="#fff"
                                    width={56}
                                    height={56}
                                    borderRadius="50%"
                                    mr={4}
                                >
                                    <ImPhone size={20} color="#000" />
                                </Flex>
                                <Box>
                                    <Text color="#fff" fontWeight={300}>
                                        {phoneHelperText}
                                    </Text>
                                    <Text color="#fff" fontSize="1.25rem">
                                        {phoneNumber}
                                    </Text>
                                </Box>
                            </Flex>
                        </Link>
                    </Stack>
                </Stack>
            </Grid>
            <Grid xs={12} sm={6} position="relative">
                <Box
                    position={{
                        xs: 'relative',
                        md: 'absolute',
                    }}
                    top={{
                        xs: 0,
                        md: '-50%',
                    }}
                    width="100%"
                >
                    <AspectRatio ratio="4256 / 2832">
                        <Image
                            quality={80}
                            src={image?.url}
                            alt={title}
                            fill
                            style={{
                                borderRadius: '0% 15% 0% 15%',
                                objectFit: 'cover',
                                overflow: 'hidden',
                            }}
                        />
                    </AspectRatio>
                </Box>
            </Grid>
        </Grid>
    )
}
