// import { ChevronIcon, Group } from '@mui/joy'
import { Box } from '@mui/joy'
import { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Divider } from '../Divider'
import { Flex } from '../Flex'
// import { FiChevronDown } from 'react-icons/fi'
import NavigationItem from './NavigationItem'

export default function NavigationNestedItem({ path, label, children }) {
    const [isToggle, setToggle] = useState(false)

    const handleToggle = () => setToggle(!isToggle)

    return (
        <Box backgroundColor={isToggle && 'rgba(255,255,255,.1)'}>
            <Flex
                width="100%"
                sx={{
                    alignItems: 'center',
                }}
                onClick={handleToggle}
            >
                <Box flex={1}>
                    <NavigationItem path={path} label={label} />
                </Box>
                <Box px={3}>
                    {isToggle ? (
                        <FiChevronUp
                            color="#000"
                            style={{
                                color: '#000',
                            }}
                        />
                    ) : (
                        <FiChevronDown
                            color="#000"
                            style={{
                                color: '#000',
                            }}
                        />
                    )}
                </Box>
            </Flex>
            {isToggle && (
                <>
                    <Divider />
                    {children}
                    {/* <Divider /> */}
                </>
            )}
        </Box>
    )
}
