import parse from 'html-react-parser'
import Head from 'next/head'

/**
 *  SEO component
 *
 * @export
 * @param {*} { fullHead } - fullHead is a string of HTML
 * @return {*}
 */
export default function SEO({ fullHead, title }) {
    return (
        <Head>
            <title>{title}</title>
            {fullHead ? parse(fullHead) : null}
        </Head>
    )
}
