import { Box } from '@mui/joy'
import Container from '../Container/Container'

// Section Component
// Usage: <Section>SubHeading</Section>
// Description: A component that wraps the content of the page
// 分段元件, 分段包裝頁面內容
export default function Section({
    children,
    fullWidth,
    backgroundColor = '#fff',
    bg = null,
    // backdropRatio = null,
    backdrop = null,
    pt = 15,
    pb = 15,
    py = null,
    ...rest
}) {
    const WrapperComponent = fullWidth ? Box : Container
    return (
        <Box
            component="section"
            position="relative"
            sx={(theme) => ({
                background: bg || backgroundColor,
                paddingTop: !py && py !== 0 ? pt : py,
                paddingBottom: !py && py !== 0 ? pb : py,
                [theme.breakpoints.down('md')]: {
                    paddingTop: !py && py !== 0 ? pt * 0.5 : py,
                    paddingBottom: !py && py !== 0 ? pb * 0.5 : py,
                },
            })}
            {...rest}
        >
            {backdrop}
            <WrapperComponent
                sx={{
                    position: 'relative',
                    zIndex: 3,
                }}
            >
                {children}
            </WrapperComponent>
        </Box>
    )
}
