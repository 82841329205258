import { Box } from '@mui/joy'

export default function Main({ children, className }) {
    return (
        <Box
            component="main"
            className={className}
            pt={{
                xs: '63px',
                md: 0,
            }}
            style={{
                background: '#fff',
                position: 'relative',
                zIndex: 10,
                // paddingTop: '68px',
            }}
        >
            {children}
        </Box>
    )
}
