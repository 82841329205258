import { Grid } from '@mui/joy'
import { Children } from 'react'

// --container-width-base: 276px;
//     --container-width-extra-small: 324px;
//     --container-width-small: 424px;
//     --container-width-medium: 680px;
//     --container-width-large: 868px;
//     --container-width-extra-large: 1016px;
export default function Content({
    children,
    center = true,
    maxW = 680,
    gap = 15,
}) {
    return (
        <Grid
            sx={{
                maxWidth: `${maxW}px`,
                margin: center ? '0 auto' : null,
            }}
            container
            columnSpacing={{
                md: `${gap}px`,
                sm: `${gap / 2}px`,
                xs: '0px',
            }}
            rowSpacing={`${gap}px`}
        >
            {Children.toArray(children).map((child, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={`Content-Item-${index}`} xs={12}>
                    {child}
                </Grid>
            ))}
        </Grid>
    )
}
