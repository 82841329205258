import dynamic from 'next/dynamic'
import { memo } from 'react'
import flatListToHierarchical from '../../utilities/flatListToHierarchical'
import { Flex } from '../Flex'
import NavigationCategoryItem from './NavigationCategoryItem'
import NavigationItem from './NavigationItem'

const LanguageSwitcher = dynamic(() => import('./LanguageSwitcher'), {
    ssr: false,
})

const renderMenuItems = (items, isDropdown) =>
    items.map((item) => {
        const { id, path, label, children } = item

        // eslint-disable-next-line no-underscore-dangle
        if (item.__typename !== 'MenuItem') {
            return null
        }

        if (children.length)
            return (
                <NavigationCategoryItem
                    key={id}
                    label={label}
                    path={path}
                    isDropdown={isDropdown}
                    // onClick={onItemClick}
                >
                    {children}
                </NavigationCategoryItem>
            )

        return (
            <NavigationItem
                // onClick={onItemClick}
                key={id}
                label={label}
                path={path}
            />
        )
    })

function NavigationBar({ menuItems, isDropdown = false, className }) {
    const hierarchicalMenuItems = flatListToHierarchical(menuItems)
    return (
        <Flex
            component="nav"
            className={className}
            flexDirection={isDropdown ? 'row' : 'column'}
        >
            {renderMenuItems(hierarchicalMenuItems, isDropdown)}
            <LanguageSwitcher />
        </Flex>
    )
}

export default memo(NavigationBar)
