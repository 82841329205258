import Image from 'next/image'
import { Overlay } from '../../Overlay'

export default function HeroBackground({ src, alt = 'Hero Background' }) {
    return (
        <Overlay>
            <Image
                quality={80}
                src={src}
                alt={alt}
                layout="fill"
                objectFit="cover"
                objectPosition="top"
            />
        </Overlay>
    )
}
