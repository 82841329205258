import { Box } from '@mui/joy'
import { useTheme } from '@mui/joy/styles'
import dynamic from 'next/dynamic'
import { useIsClient, useMediaQuery } from 'usehooks-ts'
import { Flex } from '../Flex'

import Container from '../Container/Container'
import NavigationItem from './NavigationItem'

const DesktopHeader = dynamic(() => import('./DesktopHeader'))
const MobileHeader = dynamic(() => import('./MobileHeader'), {
    ssr: false,
})

export default function Header({ menuItems }) {
    const theme = useTheme()
    const matches = useMediaQuery(
        theme.breakpoints.up('sm').replace('@media ', '')
    )
    const client = useIsClient()

    return (
        <Box
            component="header"
            pt={{
                // xs: '64px',
                sm: '109px',
            }}
        >
            <Box
                backgroundColor="#ffce00"
                sx={{
                    width: '100%',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 100,
                }}
            >
                <div>
                    <Box backgroundColor="#ffce00">
                        <Container
                            display={{
                                xs: 'none',
                                sm: 'block',
                            }}
                        >
                            <Flex justifyContent="flex-end">
                                <NavigationItem
                                    fontSize=".8rem"
                                    color="#000"
                                    subMenu
                                    label="Call (646) 887-9089"
                                    path="tel:6468879089"
                                />
                            </Flex>
                        </Container>
                    </Box>
                </div>
                {!client || matches ? (
                    <DesktopHeader menuItems={menuItems} />
                ) : (
                    <MobileHeader menuItems={menuItems} />
                )}
            </Box>
        </Box>
    )
}
