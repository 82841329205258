import { Box } from '@mui/joy'
// import Link from 'next/link'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Flex } from '../Flex'
import { Text } from '../Text'

export default function NavigationItem({
    path,
    label,
    onClick,
    color = '#000',
    fontSize = '1rem',
    fontWeight = 400,
    rightIcon = null,
    subMenu = null,
    fullWidth = false,
    sx,
}) {
    const router = useRouter()
    const routes = router.pathname.split('/')
    const isParent = routes.length && routes[1] && `/${routes[1]}/` === path
    const isActive = router.pathname === path || `${router.pathname}/` === path
    const Component = Link

    return (
        <Box
            sx={{
                cursor: 'pointer',
                // width: '100%',
                width: fullWidth ? '100%' : 'auto',
            }}
            width="auto"
            onClick={onClick ?? null}
        >
            <Component href={path || ''}>
                <Flex
                    className={isParent || isActive ? 'active' : null}
                    sx={{
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: subMenu ? '8px' : '16px',
                        paddingBottom: subMenu ? '8px' : '16px',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        justifyContent: 'space-between',
                        transition: 'all 0.3s ease-in-out',
                        borderBottom: subMenu ? '0' : '4px solid transparent',
                        color,
                        '&:hover': {
                            color: '#0d417d',
                            borderBottomColor: '#ffd44d',
                        },
                        // '&.active': {
                        //     borderColor: '#ffd44d',
                        // },
                        ...sx,
                    }}
                >
                    <Text
                        pr={1}
                        sx={{
                            textDecoration: 'none!important',
                            fontSize,
                            lineHeight: '28px',
                            color: 'inherit',
                            fontWeight,
                        }}
                    >
                        {label ?? ''}
                    </Text>
                    {rightIcon}
                </Flex>
            </Component>
        </Box>
    )
}
