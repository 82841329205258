import dynamic from 'next/dynamic'

export const BusinessLineOfCredit = {
    Hero: dynamic(() => import('./Hero')),
    Quote: dynamic(() => import('./Quote')),
    Features: dynamic(() => import('./Features')),
    // CTA: dynamic(() => import('./CTA')),
    HowItWorks: dynamic(() => import('./HowItWorks')),
    Loans: dynamic(() => import('./Loans')),
    Benefits: dynamic(() => import('./Benefits')),
    MinimumRequirements: dynamic(() => import('./MinimumRequirements')),
}
