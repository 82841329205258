import { Accordion } from '../Accordion'
import { Text } from '../Text'

export default function Faq({ title, description, value }) {
    return (
        <Accordion value={value}>
            <Accordion.Control>
                <Text color="#0d417d" fontWeight={500}>
                    {title}
                </Text>
            </Accordion.Control>
            <Accordion.Panel>
                <Text fontWeight={300}>{description}</Text>
            </Accordion.Panel>
        </Accordion>
    )
}
