import Box from '@mui/joy/Box'
import { styled } from '@mui/joy/styles'
import MenuList from '@mui/joy/MenuList'
import MenuItem from '@mui/joy/MenuItem'
import { FiChevronDown } from 'react-icons/fi'
import PopperUnstyled from '@mui/base/PopperUnstyled'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import { useCallback, useState } from 'react'
import NavigationItem from './NavigationItem'
import NavigationNestedItem from './NavigationNestedItem'

const Popup = styled(PopperUnstyled)({
    zIndex: 1000,
})

const renderMenuChildren = (children) =>
    children.map(({ id, path, label }) => (
        <MenuItem px={3} key={id} width="full">
            <NavigationItem fullWidth label={label} path={path} />
        </MenuItem>
    ))

const renderChildren = (children) =>
    children.map(({ id, path, label }) => (
        <Box width="100%" key={id}>
            <NavigationItem key={id} label={label} path={path} />
        </Box>
    ))

export default function NavigationCategoryItem({
    path,
    label,
    children,
    isDropdown,
}) {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget)
    }, [])

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleListKeyDown = useCallback((event) => {
        if (event.key === 'Tab') {
            setAnchorEl(null)
        } else if (event.key === 'Escape') {
            anchorEl?.focus()
            setAnchorEl(null)
        }
    }, [])

    if (isDropdown)
        return (
            <Box onMouseLeave={handleClose}>
                <Box onClick={handleClick} onMouseEnter={handleClick}>
                    <NavigationItem
                        label={label}
                        path={path}
                        rightIcon={<FiChevronDown color="#000" />}
                    />
                </Box>
                <Popup
                    role={undefined}
                    id="composition-menu"
                    open={open}
                    anchorEl={anchorEl}
                    disablePortal
                    modifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 0],
                            },
                        },
                    ]}
                >
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                            variant="outlined"
                            onKeyDown={handleListKeyDown}
                            sx={{
                                minWidth: 200,
                                boxShadow: 'md',
                                bgcolor: 'background.body',
                            }}
                        >
                            {renderMenuChildren(children)}
                        </MenuList>
                    </ClickAwayListener>
                </Popup>
            </Box>
        )

    return (
        <NavigationNestedItem label={label} path={path}>
            {renderChildren(children)}
        </NavigationNestedItem>
    )
}
