import { Box } from '@mui/joy'

export function Card({ children, ...rest }) {
    return (
        <Box
            {...rest}
            border="1px solid rgba(24,24,24,.125)"
            bgcolor="#fff"
            borderRadius="1rem"
            boxShadow="0 .5rem 1rem rgba(24,24,24,.15)!important"
        >
            {children}
        </Box>
    )
}
