import { Box } from '@mui/joy'
import { styled } from '@mui/joy/styles'

const CustomContainer = styled(Box)(({ theme }) => ({
    margin: '0 auto',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
        maxWidth: 540,
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: 780,
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: 1024,
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: 1280,
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: 1360,
    },
    [theme.breakpoints.down('lg')]: {
        paddingRight: '1rem',
        paddingLeft: '1rem',
    },
}))

export default function Container({ children, ...rest }) {
    return <CustomContainer {...rest}>{children}</CustomContainer>
}
